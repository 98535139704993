<template>
  <headBackBar :iCustomizdde="true" :icon="true">
    <template v-slot:left-icon>
      <van-icon
        name="arrow-left"
        @click="$fun.routerToPage('/user/userDetail')"
      />
    </template>
    <template #default>
      <span>
        {{ $fanyi("账户设置") }}
      </span>
    </template>
    <!--账户设置页面 -->
  </headBackBar>

  <!-- 内容区域 -->
  <div class="Content">
    <!-- 密码修改 -->
    <div
      class="password"
      @click="$fun.routerToPage('/user/informationmodification?type=password')"
    >
      <div class="left">
        <img src="../../../../assets/user-img/usersetting/suo.png" alt="" />
        {{ $fanyi("密码修改") }}
      </div>
      <van-icon name="arrow" />
    </div>
    <!-- 更换绑定邮箱 -->
    <div
      class="eamil"
      @click="$fun.routerToPage('/user/informationmodification?type=email')"
    >
      <div class="left">
        <img src="../../../../assets/user-img/usersetting/xinfeng.png" alt="" />
        {{ $fanyi("更换绑定邮箱") }}
      </div>
      <van-icon name="arrow" />
    </div>
    <!-- 更换绑定手机号 -->
    <div
      class="moblie"
      @click="$fun.routerToPage('/user/informationmodification?type=mobile')"
    >
      <div class="left">
        <img src="../../../../assets/user-img/usersetting/mobile.png" alt="" />
        {{ $fanyi("更换绑定手机号") }}
      </div>
      <van-icon name="arrow" />
    </div>

    <!--注销登录  -->
    <div
      class="Logout"
      @click="$fun.routerToPage('/user/closeanaccount')"
      v-if="false"
    >
      {{ $fanyi("注销账号") }}
    </div>
  </div>
</template>
<script setup>
import dayjs from "dayjs";
import headBackBar from "../../../../components/headGoBack/index.vue";
import { getCurrentInstance, ref } from "vue";
const { proxy } = getCurrentInstance();
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";

.headBox {
  span {
    font-size: 32px;
    font-family: PingFang SC !important;
    font-weight: 600;
    color: #000000;
  }
}

:deep().van-icon-arrow {
  font-size: 28px;
}

.Content {
  width: 750px;
  height: 1400px;
  background: #f6f6f6;
  background: #f6f6f6;
  padding: 0 30px;
  padding-top: 20px;

  .password {
    width: 690px;
    height: 100px;
    background: #ffffff;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 33px;
    padding-right: 28px;
    margin-bottom: 20px;

    .left {
      font-size: 28px;

      font-weight: 400;
      color: #000000;
      display: flex;
      align-items: center;

      img {
        margin-left: 4px;
        width: 32px;
        height: 36px;
        margin-right: 25px;
      }
    }
  }

  .eamil {
    width: 690px;
    height: 100px;
    background: #ffffff;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 33px;
    padding-right: 28px;
    margin-bottom: 20px;

    .left {
      font-size: 28px;

      font-weight: 400;
      color: #000000;
      display: flex;
      align-items: center;

      img {
        width: 36px;
        height: 26px;
        margin-right: 25px;
      }
    }
  }

  .moblie {
    width: 690px;
    height: 100px;
    background: #ffffff;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 33px;
    padding-right: 28px;
    margin-bottom: 20px;

    .left {
      font-size: 28px;

      font-weight: 400;
      color: #000000;
      display: flex;
      align-items: center;

      img {
        margin-left: 5px;
        width: 26px;
        height: 36px;
        margin-right: 25px;
      }
    }
  }

  .Logout {
    width: 690px;
    height: 100px;
    background: #ffffff;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;

    font-weight: 400;
    color: #000000;
  }
}
</style>
